@use "sass:math";
@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");

$gutter: 30px;
$border-radius: 15px;
$purple-dark: #5236ff;
$purple: #7f7fff;
$purple-light: #e4e4ff;

$orange: #ff7d4c;
$orange-light: #ffdcc6;

$blue-dark: #00a2ff;
$blue: #76ccff;
$blue-light: #d8f1ff;
$blue-lighter: #d8f1ff;

$white: #ffffff;

$grey-darkest: #161616;
$grey-darker: #2e2d2d;
$grey-dark: #4a4a4a;
$grey: #7a7a7a;
$grey-light: #9d9d9d;
$grey-lighter: #d7d7d7;
$grey-lightest: #f7f7f7;

$black: #000;

#home {
  background-color: white;
  color: #333333;
  font-size: 14px;
  &,
  p,
  span,
  link,
  li,
  h2,
  h1,
  h3,
  button,
  input {
    font-family: "Inter", "Helvetica", "Sans Serif" !important;
    font-weight: normal !important;
    letter-spacing: 0px !important;
    &:not(button) {
      text-align: start;
    }
  }
  strong {
    font-weight: bold;
  }
  h1 {
    font-size: 20px !important;
  }
  h2 {
    font-size: 20px !important;
  }
  h3 {
    font-size: 16px !important;
  }
  h4 {
    font-size: 15px !important;
  }
  hr {
    border: 0;
    clear: both;
    display: block;
    background-color: #eee;
    height: 1px;
    margin: calc($gutter/2) 0;
  }
  & > .header {
    display: flex;
    justify-content: center;
    flex-direction: column;
    .top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .left {
        display: flex;
        flex-direction: column;
        button {
          text-align: left;
        }
      }
      .right {
        display: flex;
        align-items: center;
        button {
          font-size: 25px;
          margin-left: $gutter;
        }
      }
      margin-bottom: $gutter;
    }
  }

  .tabList {
    display: flex;
    .tab {
      padding: 10px;
      border-radius: 5px 5px 0 0;
      &.active {
        background-color: $white;
      }
    }
  }

  &.mobile {
    height: 100vh;
    font-size: 13px;
    input {
      font-size: 16px;
    }
    & > .header,
    & > .content {
      padding: math.div($gutter, 2);
    }
    & > .header {
      background: $grey-lightest;
      padding-bottom: 0;
      display: flex;
      justify-content: center;
      flex-direction: column;
      .top {
        h2 {
          font-size: 20px;
        }
        margin-bottom: math.div($gutter, 2);
      }
    }
    .tabList > .tab {
      font-size: 12px;
    }
  }

  &:not(.mobile) {
    & > .header,
    & > .content {
      padding: $gutter;
    }
    .tabList {
      justify-content: center;
    }
    & > .header {
      padding-bottom: 0;
      background: $grey-lightest;
      display: flex;
      justify-content: center;
      flex-direction: column;
    }
  }

  input:not([type="checkbox"]) {
    background-color: $grey-lightest;
    border-radius: $border-radius;
    padding: math.div($gutter, 4) math.div($gutter, 2);
    margin-top: 5px;
  }
  input[type="checkbox"] {
    margin-right: 5px;
  }
  label {
    font-weight: bold;
    color: $grey;
    display: flex;
    flex-direction: column;
    margin-bottom: math.div($gutter, 2);
    &.subscribe {
      flex-direction: row;
    }
    select {
      margin-top: 5px;
    }
  }

  button.corporate {
    padding: math.div($gutter, 4) math.div($gutter, 2);
    border-radius: $border-radius;
    color: $black;
    border-color: transparent;
    background-color: $grey-lightest;
    text-align: center;
    &.fullWidth {
      width: 100%;
    }
    &.danger {
      background-color: $orange;
    }
  }

  button.underline {
    text-decoration: underline;
    font-weight: bold;
  }

  .twoColumns {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: $gutter;
    & > * {
      max-width: 100%;
      overflow: hidden;
    }
  }
  .threeColumns {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: $gutter;
    & > * {
      max-width: 100%;
      overflow: hidden;
    }
  }

  & > .content {
    padding: $gutter;
    background-color: white;
    & > .header {
      margin-bottom: $gutter;
    }
  }
  .orders {
    .orderTable {
      & > .header {
        margin-bottom: $gutter;
      }
      .orderTableItem {
        display: grid;
        grid-gap: math.div($gutter, 2);
        align-items: center;
        grid-template-columns: 1.5fr 1fr 2fr 1fr 1fr 0.5fr;
        margin-bottom: math.div($gutter, 4);
      }
    }
  }
  #order {
    & > .content {
      display: grid;
      grid-template-columns: 1fr 2fr;
      grid-gap: $gutter;
      & > .left {
        & > .header {
          display: flex;
          justify-content: space-between;
        }
        .summary {
          .justify {
            display: flex;
            justify-content: space-between;
            margin-bottom: math.div($gutter, 4);
            &.total {
              .price {
                font-size: 30px;
              }
            }
          }
        }
      }
      & > .right {
        .items {
          margin-bottom: $gutter * 2;
          .itemEntry {
            display: grid;
            grid-gap: math.div($gutter, 2);
            grid-template-columns: 0.5fr 4fr 1fr 1fr;
            .options > p {
              font-size: 0.6rem;
            }
          }
        }
        .messageSection {
          & > .header {
            margin-bottom: math.div($gutter, 2);
          }
          form.newMessage {
            display: flex;
            flex-direction: column;
            margin-bottom: math.div($gutter, 2);
            textarea {
              width: 100%;
              padding: math.div($gutter, 2);
              border-radius: $border-radius;
              background-color: $grey-lightest;
              margin-bottom: math.div($gutter, 4);
            }
          }
          .messages {
            .message {
              border-radius: $border-radius;
              padding: math.div($gutter, 2);
              .header {
                margin-bottom: math.div($gutter, 2);
              }
              &:nth-child(odd) {
                background-color: $grey-lightest;
              }
            }
          }
        }
      }
    }
  }
  #details {
    &.emailUpdate,
    &.passwordUpdate {
      max-width: 400px;
      margin: 0 auto;
    }
    & > .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    & > .content {
      label {
        display: flex;
        flex-direction: column;
      }
      label.checkbox {
        flex-direction: row;
      }
      .inline {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: $gutter;
      }
      .mainDetails {
        display: grid;
        grid-gap: $gutter;
        grid-template-columns: 1fr 1fr;
      }
    }
    .actions {
      text-align: center;
      button {
        margin: 0 math.div($gutter, 4);
      }
    }
  }
  #addresses {
    max-width: 400px;
    margin: 0 auto;
    .addresseList {
      display: grid;
      grid-template-columns: 1fr 1fr;
      margin-bottom: $gutter;
      address {
        margin-bottom: math.div($gutter, 2);
      }
    }
    & .content {
      display: grid;
      grid-gap: $gutter;
      max-width: 400px;
      margin: 0 auto;
      grid-template-columns: 1fr;

      .address {
        margin-bottom: $gutter;
      }
    }
    & > .actions {
      display: flex;
      justify-content: center;
      &.spread {
        justify-content: space-between;
      }
      button {
        margin: 0 math.div($gutter, 4);
      }
    }
  }
  #settings {
    max-width: 400px;
    margin: 0 auto;
    .actions {
      text-align: center;
    }
    button {
      margin: math.div($gutter, 2);
    }
  }
  #login {
    max-width: 300px;
    margin: 0 auto;
    form {
      margin-bottom: $gutter;
      .submit {
        text-align: center;
      }
    }
    .buttons {
      display: flex;
      flex-direction: column;
      text-align: center;
      & > * {
        margin-bottom: 5px;
      }
    }
    display: flex;
    flex-direction: column;
  }
  #register {
    max-width: 450px;
    margin: 0 auto;
    form {
      .submit {
        text-align: center;
      }
    }
    display: flex;
    flex-direction: column;
  }
  #resetPassword {
    max-width: 300px;
    margin: 0 auto;
    form {
      .submit {
        text-align: center;
      }
    }
    display: flex;
    flex-direction: column;
  }

  .basket {
    &.empty {
      display: flex;
      justify-content: center;
      flex-direction: column;
      &,
      h2 {
        text-align: center;
      }
      .buttons {
        display: flex;
        justify-content: center;
        button {
          margin: math.div($gutter, 2);
        }
      }
    }
    &.mobile {
      & > .content {
        grid-template-columns: 1fr;
        .basketEntry {
          .image {
            display: none;
          }
          grid-template-columns: 1fr 0.2fr 0.2fr;
        }
      }
    }
    & > .content {
      display: grid;
      grid-gap: $gutter;
      grid-template-columns: 3fr 1fr;
      .basketEntry {
        margin-bottom: math.div($gutter, 2);
        display: grid;
        grid-gap: 10px;
        grid-template-columns: 50px 2fr 0.2fr 0.2fr;
        .descriptionAndOptions {
          p.description {
            margin-bottom: math.div($gutter, 4);
          }
        }
        .options {
          font-size: 12px;
        }
        .sale,
        .total {
          text-align: right;
        }
        .quantityEditor {
          display: flex;
          align-items: center;
          input {
            margin: 0 math.div($gutter, 2);
            max-width: 70px;
          }
          button {
            padding: 0;
            width: 20px;
            height: 20px;
            img {
              width: 100%;
              height: auto;
            }
          }
        }
      }
      .summary {
        .justify {
          display: flex;
          justify-content: space-between;
          margin-bottom: 10px;
        }
        button#proceedToCheckout {
          width: 100%;
          padding: 15px 25px;
          text-align: center;
          cursor: pointer;
          outline: none;
          color: #000;
          background-color: #f7f7f7;
          border: none;
          border-radius: 40px;
          transition-duration: 0.5s;
          opacity: 1;
          &:hover {
            background-color: #000;
            color: #fff;
          }
          &:active {
            background-color: #000;
            box-shadow: 0 5px #666;
            transform: translateY(4px);
          }
        }
      }
    }
  }
}
