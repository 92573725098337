#catalogue {
  max-width: var(--max-width);
  margin: 0 auto;
  padding: var(--gutter);
  h1 {
    margin-bottom: var(--gutter);
  }
  & > .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left {
      display: flex;
    }
    button {
      margin-right: var(--gutter);
    }
  }
  & > .content {
    padding: var(--gutter) 0;
    display: grid;
    grid-template-columns: 1fr;
    &.showFilters {
      grid-gap: var(--gutter);
      grid-template-columns: 1fr 3fr;
    }

    #filters {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: calc(var(--gutter) / 2);
      display: flex;
      flex-direction: column;
      &.mobile {
        background-color: white;
        padding: calc(var(--gutter) * 2);
        z-index: 2;
        &.closed {
          transform: translateX(-100vw);
        }
        &.open {
          transform: translateX(0);
        }
        & > .header {
          font-size: 1.3rem;
          margin-bottom: calc(var(--gutter) * 2);
          display: flex;
          justify-content: space-between;
        }
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        overflow-y: scroll;
      }
      .filterSection {
        margin-bottom: calc(var(--gutter));
        .header {
          h2,
          button {
            vertical-align: baseline;
            i {
              margin-left: calc(var(--gutter) / 2);
            }
          }
          .name {
            font-weight: bold;
          }
          align-items: center;
          display: flex;
          justify-content: space-between;
          font-weight: normal;
          margin-bottom: calc(var(--gutter) / 2);
        }
        &.column,
        &.row {
          .filterEntries {
            display: grid;
            grid-template-columns: 1fr 1fr;
          }
        }
        .filter {
          padding-left: 0;
          margin-left: 0;
          label {
            cursor: pointer;
            input[type="checkbox"] {
              margin-right: calc(var(--gutter) / 2);
            }
          }
        }
      }
      .clearFilters {
        button {
          width: 100%;
          display: flex;
          justify-content: space-between;
        }
        text-transform: uppercase;
      }
    }
    .entries {
      display: grid;
      grid-gap: var(--gutter);
      justify-content: space-between;
      grid-template-columns: repeat(2, 1fr);
      grid-template-columns: repeat(var(--collection-columns), 1fr);
      grid-auto-rows: max-content;
    }
  }
}
