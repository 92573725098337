#thankyou {
  max-width: var(--max-width);
  margin: 0 auto;
  padding: var(--gutter);
  .content {
    text-align: center;
    display: flex;
    flex-direction: column;
    h1,
    h2 {
      font-size: 2rem;
    }
  }
}
