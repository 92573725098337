#terms,
#shippingPolicies {
  max-width: var(--max-width);
  margin: 0 auto;
  padding: var(--gutter);
  & > .header {
    margin-bottom: calc(var(--gutter) * 2);
  }
  & > .content {
    p {
      margin-bottom: var(--gutter);
    }
  }
}
