#searchResults {
  max-width: var(--max-width);
  margin: 0 auto;
  padding: var(--gutter);
  & > .header {
    margin-bottom: var(--gutter);
  }
  & > .content {
    .entries {
      display: grid;
      grid-gap: var(--gutter);
      grid-template-columns: repeat(var(--collection-columns), 1fr);
    }
  }
}
