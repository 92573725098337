#superHeader {
  &.mobile {
    display: none;
  }
  & > .content {
    max-width: var(--max-width);
    margin: 0 auto;
    padding: 0 var(--gutter);
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-transform: uppercase;
    & > .right {
      display: flex;
      justify-content: flex-end;
      display: flex;
      & > * {
        margin: calc(var(--gutter) / 2);
      }
    }
  }
  font-size: 12px;
}

#header {
  &.sticky {
    position: sticky;
    top: 0;
  }
  z-index: 2;
  &.mobile {
    & > .content > * {
      flex: 1;
    }
    &.leftAligned > .content {
      justify-content: space-between;
      .logo {
        padding: 0 10px;
      }
    }
    &.centered > .content {
      .logo {
        display: flex;
        justify-content: center;
      }
    }
    & > .content {
      display: flex;
      align-items: center;
      padding: var(--gutter);
      max-width: var(--max-width);
      margin: 0 auto;
      .profileAndBasket,
      .burger {
        font-size: 25px;
      }
      .profileAndBasket {
        display: flex;
        justify-content: flex-end;
        button {
          padding: 10px;
          cursor: pointer;
        }
      }
      .menu.header {
        padding: 0;
      }
    }
    & > #search {
      font-size: 16px;
      input {
        font-size: 16px;
      }
    }
    .logo > a > img {
      max-width: 200px !important;
      min-width: 10px !important;
    }
    #mobileMenu {
      width: 100vw;
      position: absolute;
      z-index: 999;
      overflow: scroll;
      -webkit-transition: 0.3s ease-in-out;
      transition: 0.3s ease-in-out;
      &.open {
        height: 100vh;
      }
      &.closed {
        height: 0;
      }
      & > .content {
        padding: 20px;
        padding-bottom: 80px;
        display: flex;
        flex-direction: column;
        text-align: center;
        & > .menu {
          margin-bottom: calc(var(--gutter) * 2);
          & > .content,
          & > .content > .group {
            display: flex;
            flex-direction: column;
          }
        }
        .dropdown {
          display: flex;
          justify-content: center;
          flex-direction: column;
          align-items: center;
          .top {
            text-align: center;
            button {
              display: flex;
              i {
                font-size: 20px;
                padding: 0 2px;
              }
            }
          }
          .subMenu {
            display: none;
            &.open {
              display: block;
            }
          }
        }
      }
    }
  }
  &:not(.mobile) {
    .menu.header {
      .content {
        .menuLink {
          margin-right: calc(var(--gutter));
        }
      }
    }

    &.centered {
      & > .content > * {
        flex: 1;
      }
      & > .content {
        padding: var(--gutter);
        max-width: var(--max-width);
        margin: 0 auto;
        display: flex;
        align-items: center;
        & > .menu {
          display: flex;
          order: 0;
          & > .content,
          & > .content > .group {
            display: flex;
            justify-content: flex-start;
            & > *,
            & > .group {
              flex-grow: 0;
            }
            .logo,
            & > * {
              margin-right: calc(var(--gutter));
            }
          }
        }
        .logo {
          display: flex;
          justify-content: center;
          order: 1;
        }
        .profileAndBasket {
          order: 2;
          display: flex;
          justify-content: flex-end;
          font-size: 25px;
          & > * {
            margin: calc(var(--gutter) / 2);
          }
          button {
            padding: 5px;
          }
        }
      }
    }
    &.leftAligned {
      display: block;
      & > .content {
        padding: var(--gutter);
        max-width: var(--max-width);
        margin: 0 auto;
        display: flex;
        align-items: center;
        .profileAndBasket {
          order: 2;
          display: flex;
          justify-content: flex-end;
          font-size: 25px;
          margin-left: auto;
          & > * {
            margin: calc(var(--gutter) / 2);
          }
          button {
            padding: 5px;
          }
        }
        & > .menu {
          display: flex;
          & > .content,
          & > .content > .group {
            display: flex;
            .logo,
            & > * {
              margin-right: calc(var(--gutter));
            }
          }
        }
        .menu {
          order: 1;
        }
        .logo {
          order: 0;
          display: flex;
          justify-content: center;
          margin-right: calc(var(--gutter));
        }
      }
    }
  }
  & > #search {
    max-width: var(--max-width);
    margin: 0 auto;
    form {
      padding: var(--gutter);
      border-radius: var(--border-radius);
      display: flex;
      justify-content: space-between;
      i {
        margin-right: var(--gutter);
      }
    }
    height: 0;
    overflow-y: hidden;
    &.open {
      height: auto;
    }
  }
}

.languageSelector {
  position: relative;
  height: auto;
  overflow-y: visible;
  button {
    text-transform: uppercase;
    text-align: left;
    font-size: inherit;
  }
  .current {
    display: flex;
    button {
      i {
        margin: 0 calc(var(--gutter) / 2);
      }
    }
  }
  .entries {
    margin-top: calc(var(--gutter) / 2);
    position: absolute;
    border-radius: 4px;
    opacity: 1;
    z-index: 3;
    top: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    .entry {
      padding: 0px 10px;
      margin: 5px 0;
    }
  }
}
