.pagination {
  padding: var(--gutter) 0;
  display: flex;
  justify-content: flex-end;
  .naviguator {
    display: flex;
    justify-content: space-between;
    li {
      margin: 0 10px;
    }
  }
}
