#artists {
  max-width: var(--max-width);
  margin: 0 auto;
  padding: var(--gutter);
  & > .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: calc(var(--gutter) * 2);
    .abecedaire {
      text-transform: uppercase;
      a:nth-child(1) {
        word-wrap: normal;
        word-break: keep-all;
        white-space: nowrap;
      }
      a {
        margin: 3px;
        &.active {
          font-weight: bold;
          text-decoration: underline;
        }
      }
    }
  }
  .content {
    .entry {
      margin-bottom: calc(var(--gutter) * 2);
      padding: calc(var(--gutter) / 2);
      &.active {
        h3 {
          text-decoration: underline;
        }
      }
      h3 {
        text-transform: uppercase;
        margin-bottom: calc(var(--gutter) * 2);
      }
      p {
        margin-bottom: calc(var(--gutter) / 4);
      }
    }
  }
  &.mobile {
    & > .header {
      flex-direction: column;
      max-width: 100%;
    }
    .abecedaire {
      text-transform: uppercase;
      a {
        margin: 2px;
      }
    }
  }
}
