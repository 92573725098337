#article {
  .row {
    padding: calc(var(--gutter) * 1);
    max-width: var(--max-width);
    margin: 0 auto;
    &.noPadding {
      padding: 0;
    }
    img {
      width: 100%;
    }
    &.fullWidth {
      max-width: 100vw;
    }
    margin-bottom: calc(var(--gutter) * 2);
    &.twoColumn {
      display: grid;
      grid-gap: var(--gutter);
      grid-template-columns: repeat(2, 1fr);
    }
    &.threeColumn {
      display: grid;
      grid-gap: var(--gutter);
      grid-template-columns: repeat(3, 1fr);
    }
    &.mobile {
      grid-template-columns: 1fr;
    }
  }

  .block.playlist {
    .playlistEntry {
      display: flex;
      flex-direction: column;
    }
  }
  .block.collection,
  .block.playlist {
    margin-bottom: calc(var(--gutter) * 2);
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: var(--gutter);
    }
    &:not(.mobile) {
      .entries {
        display: grid;
        grid-gap: var(--gutter);
        grid-template-columns: repeat(var(--collection-columns), 1fr);
      }
    }
    &.mobile {
      .content {
        max-width: 100%;
        display: grid;
      }
      .entries {
        &::-webkit-scrollbar {
          display: none;
        }
        -ms-overflow-style: none;
        scrollbar-width: none;
        display: grid;
        --visible-cols: var(--collection-columns);
        --col-gap: var(--gutter);
        --col-hint: var(--gutter);
        --scrollbar-padding: 20px;
        --col-size: calc((100% / var(--visible-cols)) - var(--col-gap) - var(--col-hint));

        display: grid;
        grid-auto-flow: column;
        grid-template-columns: var(--col-size);
        grid-auto-columns: var(--col-size);
        overflow-x: auto;
        overflow-y: hidden;
        grid-gap: var(--col-gap);
      }
    }
  }

  .block.carousel {
    .carouselContainer {
      .slick-dots {
        display: flex !important;
        justify-content: center;
        display: inline-block;
        vertical-align: middle;
        margin-top: 30px;
        padding: 0;
        text-align: center;
        li {
          margin: 0 0px;
          button {
            &::before {
              font-size: 12px;
            }
          }
        }
      }
      .slick-slider {
        min-width: 100%;
        width: 0;
        img {
          width: 100%;
          min-width: 100%;
          display: inline-block;
        }
      }
    }
  }

  .block.items {
    .entries {
      display: grid;
      grid-gap: var(--gutter);
    }
    .title {
      margin-bottom: var(--gutter);
    }
  }

  .block.separator {
    hr {
      margin: calc(var(--gutter) * 2) 0;
      border-top: 1px solid grey;
    }
  }

  .block.blog {
    .entries {
      display: grid;
      grid-gap: var(--gutter);
      &.columns-3 {
        grid-template-columns: repeat(3, 1fr);
      }
      &.columns-2 {
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }
}

.block.subscribe {
  margin: calc(var(--gutter) * 2) auto;
  h2 {
    margin-bottom: var(--gutter);
  }
  .content {
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    input {
      padding: 5px;
      min-width: 300px;
      border-radius: var(--border-radius);
    }
    button {
      border-color: var(--cg-primary);
    }
  }
  &.mobile {
    .content {
      display: flex;
      flex-direction: column;
      input {
        min-width: 50px;
        font-size: 16px;
      }
    }
  }
}
