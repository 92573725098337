.suggestions {
  h3 {
    margin-bottom: var(--gutter);
  }
  & > .content {
    display: grid;
    grid-gap: var(--gutter);
    grid-template-columns: repeat(var(--collection-columns), 1fr);
  }
}
