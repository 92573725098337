#collection {
  max-width: var(--max-width);
  margin: 0 auto;
  padding: var(--gutter);

  .header {
    margin-bottom: calc(var(--gutter) * 2);
    h1,
    .banner,
    .description {
      margin-bottom: var(--gutter);
    }
  }
  .sortAndPagination {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    .pagination {
      padding: 0;
    }
  }
  .entries.tileView {
    display: grid;
    grid-gap: var(--gutter);
    grid-template-columns: repeat(var(--collection-columns), 1fr);
  }
  .entries.listView,
  .entries.fullView {
    display: grid;
    grid-gap: var(--gutter);
    grid-template-columns: 1fr;
  }
}
