#app {
  display: grid;
  grid-template-rows: auto auto 1fr auto;
  &.mobile {
    grid-template-rows: auto 1fr;
  }
  #content {
    display: grid;
    &.mobile {
      .menu.navbar,
      .menu.header {
        display: none;
      }
    }
    &.centered {
      &.mobile {
        grid-template-rows: 1fr auto;
        grid-template-columns: 1fr;
        grid-template-areas: "main" "footer";
      }
      &:not(.mobile) {
        grid-template-rows: auto 1fr auto;
        grid-template-areas: "navbar" "main" "footer";
        .menu > .content {
          padding: var(--gutter);
          &,
          & > .group {
            flex-direction: row;
            align-items: center;
          }
        }
      }
    }
    &.leftAligned {
      max-width: var(--max-width);
      margin: 0 auto;
      &.mobile {
        grid-template-rows: 1fr auto;
        grid-template-columns: 1fr;
        grid-template-areas: "main";
      }
      &:not(.mobile) {
        grid-template-columns: 20% repeat(3, 1fr);
        grid-template-areas:
          "navbar main main main"
          "navbar main main main";
        width: 100%;
        .menu.navbar {
          .content {
            padding: var(--gutter);
            &,
            & > .group {
              flex-direction: column;
              align-items: flex-start;
            }
          }
        }
      }
    }
  }
}
