#player {
  position: fixed;
  bottom: 0;
  width: 100%;
  &.hidden {
    display: none;
  }
  z-index: 2;
  & > .content {
    padding: var(--gutter);
    display: grid;
    align-items: center;
    grid-gap: calc(var(--gutter) / 1);
    grid-template-columns: 2fr 3fr 0.4fr;
    grid-template-areas: "left waveform right";
    &.mobile {
      grid-template-columns: 2fr 1fr;
      grid-template-areas:
        "left left"
        "waveform right";
    }
    z-index: 1;
    & > .left {
      margin-top: 5px;
      grid-area: left;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .artworkAndTitle {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        img {
          height: 30px;
          width: auto;
          margin-right: 10px;
        }
      }

      a {
        white-space: nowrap;
        &:hover {
          border-bottom: 1px dotted;
        }
      }
      .loader {
        margin: 0;
        margin-left: calc(var(--gutter) * 2);
        margin-right: calc(var(--gutter) * 2);
      }
      .controls {
        display: flex;
        justify-content: space-between;
        align-items: center;
        button {
          margin: 0 5px;
        }
      }
    }

    #waveform {
      grid-area: waveform;
      width: 100%;
      overflow: hidden;
      & > wave {
        overflow: hidden;
      }
    }
    .right {
      margin-top: 5px;
      grid-area: right;
      text-align: right;
      display: flex;
      justify-content: space-between;
      align-items: center;
      button {
        background-color: transparent;
        border: none;
        padding: 0;
        img {
          height: auto;
          // width: var(--cg-text-xl);
        }
      }
    }
  }
}
