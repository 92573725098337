// unicode-range: U+000-5FF;

[class^="cg-icon-"],
[class*=" cg-icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "cg-icons" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  // /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/****** CG Icons ******/

.cg-icon-basket-cart:before {
  content: "\ea00";
}
.cg-icon-basket-cart-preorder:before {
  content: "\ea01";
}
.cg-icon-basket-selected:before {
  content: "\ea02";
}
.cg-icon-wantlist:before {
  content: "\ea03";
}
.cg-icon-wantlist-fill:before {
  content: "\ea04";
}
.cg-icon-account:before {
  content: "\ea05";
}
.cg-icon-search:before {
  content: "\ea06";
}
.cg-icon-shipping:before {
  content: "\ea07";
}
.cg-icon-play:before {
  content: "\ea0A";
}
.cg-icon-pause:before {
  content: "\ea0B";
}
.cg-icon-player-prev:before {
  content: "\ea0C";
}
.cg-icon-player-next:before {
  content: "\ea0D";
}
.cg-icon-player-outline:before {
  content: "\ea0E";
}
.cg-icon-filter:before {
  content: "\ea10";
}
.cg-icon-close:before {
  content: "\ea11";
}
.cg-icon-down:before {
  content: "\ea12";
}
.cg-icon-add:before {
  content: "\ea13";
}
.cg-icon-remove:before {
  content: "\ea14";
}
.cg-icon-burger:before {
  content: "\ea15";
}
.cg-icon-filter-less:before {
  content: "\ea17";
}
.cg-icon-filter-more:before {
  content: "\ea18";
}
.cg-icon-filter-close:before {
  content: "\ea19";
}
.cg-icon-languages:before {
  content: "\ea22";
}

/****** CG Share socials ******/

.cg-icon-share-link:before {
  content: "\1F517";
}
.cg-icon-share-facebook:before {
  content: "\EB00";
}
.cg-icon-share-instagram:before {
  content: "\EB01";
}
.cg-icon-share-twitter:before {
  content: "\EB02";
}
.cg-icon-share-pinterest:before {
  content: "\EB03";
}

/****** CG Socials Icons ******/

.cg-icon-discogs:before {
  content: "\ea60";
}
.cg-icon-ebay:before {
  content: "\ea61";
}
.cg-icon-facebook:before {
  content: "\ea62";
}
.cg-icon-instagram:before {
  content: "\ea63";
}
.cg-icon-soundcloud:before {
  content: "\ea64";
}
.cg-icon-mixcloud:before {
  content: "\ea65";
}
.cg-icon-bandcamp:before {
  content: "\ea66";
}
.cg-icon-twitter:before {
  content: "\ea67";
}
.cg-icon-youtube:before {
  content: "\ea68";
}
.cg-icon-mail:before {
  content: "\ea69";
}
.cg-icon-spotify:before {
  content: "\ea6A";
}
.cg-icon-apple-music:before {
  content: "\ea6B";
}
.cg-icon-phone:before {
  content: "\ea6C";
}
.cg-icon-mail:before {
  content: "\ea6D";
}
.cg-icon-maps:before {
  content: "\ea6E";
}
