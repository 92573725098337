#unsubscribe {
  max-width: var(--max-width);
  margin: 0 auto;
  padding: var(--gutter);

  .content {
    max-width: 600px;
    margin: 0 auto;
    h2 {
      margin-bottom: var(--gutter);
    }
    form {
      .entries {
        display: flex;
        flex-direction: column;
        margin-bottom: var(--gutter);
      }
    }
  }
}
