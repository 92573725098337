#labels {
  max-width: var(--max-width);
  margin: 0 auto;
  padding: var(--gutter);
  padding-bottom: calc(var(--gutter) * 2);
  .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: calc(var(--gutter) * 2);
    .abecedaire {
      text-transform: uppercase;
      a:nth-child(1) {
        word-wrap: normal;
        word-break: keep-all;
        white-space: nowrap;
      }
      a {
        padding: 2px;
        &.active {
          font-weight: bold;
          text-decoration: underline;
        }
      }
    }
  }
  .content {
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    grid-gap: calc(var(--gutter));
    .entry {
      .img {
        img {
          object-fit: cover;
          height: 100px;
          width: 100%;
          margin-bottom: var(--gutter);
        }
      }
      p {
        font-weight: bold;
        margin-top: calc(var(--gutter) / 4);
      }
    }
  }
  &.mobile {
    & > .header {
      flex-direction: column;
      .abecedaire {
        overflow-wrap: break-word;
        word-wrap: break-word;
        -ms-word-break: break-all;
        word-break: break-all;
        word-break: break-word;
      }
    }
    .content {
      grid-template-columns: repeat(4, 1fr);
      .entry {
        img {
          height: 70px;
        }
      }
    }
  }
}
