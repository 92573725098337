#four0Four {
  max-width: var(--max-width);
  margin: 0 auto;
  padding: var(--gutter);
  & > .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    h1 {
      text-align: center;
      margin-top: 10vh;
      font-size: 4rem;
    }
  }
}
