.menu {
  .dropdown {
    &:not(.mobile) {
      position: relative;
      .top {
        a {
          display: flex;
          align-items: center;
          padding-top: 0;
          line-height: 1;
          letter-spacing: inherit;
          text-transform: inherit;
        }
        button {
          display: flex;
          i {
            font-size: 20px;
            padding: 0 2px;
          }
        }
      }
      .subMenu {
        &:not(.sticky) {
          position: absolute;
        }
        display: none;
        &.open {
          display: block;
        }
        width: 200px;
        z-index: 3;
        top: 30px;
        left: -10px;
        border-radius: 3px;
        ul {
          li {
            margin: 0;
            padding: 4px 10px;
            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
    &.mobile {
      .top button {
        padding: 0 5px;
        margin-bottom: 10px;
      }
    }
  }
  .socialNetworks {
    i {
      margin-right: calc(var(--gutter) / 2);
    }
  }
  &.mobile {
    .link,
    .dropdown .entry {
      margin-bottom: 8px;
    }
  }
}

.menu.navbar {
  &.sticky {
    position: sticky;
    top: var(--gutter);
  }
  .content {
    max-width: var(--max-width);
    margin: 0 auto;
    display: flex;
    .group {
      display: flex;
    }
    .image {
      max-width: 80px;
    }
  }
  &.centered {
    .content {
      justify-content: center;
      & > *:not(.group),
      .group > * {
        margin-right: calc(var(--gutter));
      }
    }
  }
  &.leftAligned {
    .content {
      justify-content: flex-start;
      & > *:not(.group),
      .group > * {
        margin-bottom: 5px;
        margin-right: calc(var(--gutter));
      }
    }
  }
}

.menu.footer {
  .content {
    padding: calc(var(--gutter) * 2);
    max-width: var(--max-width);
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    .group {
      display: flex;
      flex-direction: column;
    }
    .image {
      max-width: 80px;
    }
  }
  &.mobile {
    .content {
      display: flex;
      flex-wrap: wrap;
      & > * {
        flex-basis: 100%;
        margin-bottom: var(--gutter);
      }
    }
  }
}

.menu.topHeader {
  .content {
    padding: calc(var(--gutter) / 2) var(--gutter);
    max-width: var(--max-width);
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    text-transform: uppercase;
    .group {
      display: flex;
      flex-direction: row;
      & > * {
        margin: 0 calc(var(--gutter) / 2);
      }
    }
    .image {
      max-width: 80px;
    }
  }
  &.mobile {
    display: none;
  }
}
