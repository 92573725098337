@import "./styles/reset";
@import "./styles/blocks";
@import "./styles/item";
@import "./styles/views";
@import "./styles/catalogue";
@import "./styles/headers";
@import "./styles/fonts";
@import "./styles/cookies";
@import "./styles/pagination";
@import "./styles/legal";
@import "./styles/suggestions";
@import "./styles/search";
@import "./styles/home";
@import "./styles/player";
@import "./styles/menus";
@import "./styles/artists";
@import "./styles/labels";
@import "./styles/404";
@import "./styles/collections";
@import "./styles/unsubscribe";
@import "./styles/thankyou";

html,
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "sans-serif";
}

#app {
  min-height: 100vh;
}

img {
  width: 100%;
  height: auto;
  border-radius: 3px;
}

.ReactModal__Overlay {
  z-index: 2;
  .ReactModal__Content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: 0 0 10px 0 rgba(black, 0.2);
  }
}

.price {
  &.discounted {
    text-decoration: line-through;
    margin-left: 5px;
  }
}

#collection {
  padding: var(--gutter);
}

#maintenance {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px;
  h1 {
    font-size: 30px;
    margin-bottom: 20px;
  }
  #login {
    margin-top: 30px;
    input {
      display: flex;
      flex-direction: column;
      padding: 10px;
      background-color: #eee;
    }
  }
}

.privateAccess {
  max-width: 50vw;
  &.mobile {
    padding: calc(var(--gutter) * 2);
    max-width: 90vw;
    .logo {
      img {
        max-width: 30vw;
      }
    }
  }
  margin: 0 auto;
  padding: calc(var(--gutter) * 4);
  & > * {
    margin-bottom: calc(var(--gutter) * 4);
  }
  .logo {
    display: flex;
    justify-content: center;
    img {
      max-width: 10vw;
    }
  }
  .message {
    h2 {
      text-align: center !important;
    }
  }
  .form {
    display: flex;
    // justify-content: center;
    flex-direction: column;
    align-items: center;
    form {
      display: flex;
      flex-direction: column;
      label {
        display: block;
      }
    }
    button.back {
      margin: calc(var(--gutter) * 2);
    }
  }
}

input[type="checkbox"] {
  -webkit-appearance: checkbox !important;
  -moz-appearance: checkbox !important;
  -ms-appearance: checkbox !important;
  -o-appearance: checkbox !important;
  appearance: checkbox !important;
}
