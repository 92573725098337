#cookies {
  position: fixed;
  width: 100%;
  bottom: 20px;
  display: flex;
  justify-content: center;
  .content {
    background-color: inherit;
    z-index: 999;
    cursor: pointer;
    border-radius: var(--border-radius);
    width: var(--max-width);
    justify-content: space-between;
    display: flex;
    box-sizing: border-box;
    padding: var(--gutter);
    box-shadow: 0 0 10px 0 rgba(black, 0.3);
    p {
      margin: 0;
      button {
        padding: var(--gutter) 0;
        text-decoration: underline;
      }
    }
    .buttons {
      display: flex;
      button {
        padding: 0 var(--gutter);
        text-transform: uppercase;
      }
    }
    p {
      max-width: 50%;
      margin-bottom: 0;
      color: inherit;
    }
  }

  &.mobile {
    .content {
      width: 90vw;
      left: 5%;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      .buttons {
        width: 100%;
        justify-content: space-between;
      }
      p {
        margin-bottom: var(--gutter);
        max-width: none;
      }
    }
  }
}

#cookiesModal {
  margin: auto 0;
  max-width: 680px;

  form {
    & > * {
      margin-bottom: var(--gutter);
    }
  }

  &:not(.mobile) {
    .inline {
      justify-content: flex-start;
      .acceptSelection {
        margin-right: calc(var(--gutter) * 1);
      }
    }
  }

  .inline {
    display: flex;
    justify-content: space-between;
    button {
      text-transform: uppercase;
    }
  }
  .cookieOption {
    display: flex;
    align-items: flex-start;
    .cookieDescription {
      margin-left: var(--gutter);
    }
  }
  a {
    text-decoration: underline;
  }
}
