$columGap: 30px;

#item {
  max-width: var(--max-width);
  margin: 0 auto;
  padding: var(--gutter);

  & > .content {
    display: grid;
    grid-gap: var(--gutter);
    margin-bottom: calc(var(--gutter) * 2);
    .images {
      img.main {
        margin-bottom: calc(var(--gutter) / 2);
      }
      .thumbnails {
        img {
          &:hover {
            cursor: pointer;
          }
          max-width: 50px;
          margin-right: calc(var(--gutter) / 2);
        }
      }
      margin-bottom: calc(var(--gutter));
    }
    .shareButton {
      .content {
        display: flex;
        button {
          i {
            height: 100%;
            line-height: 1.4;
          }
          margin: 0 var(--gutter);
        }
      }
    }
    .tracklist {
      margin-bottom: var(--gutter);
      .track {
        display: flex;
        justify-content: space-between;
        margin-bottom: calc(var(--gutter) / 2);
        .left {
          & > * {
            margin-right: var(--gutter);
          }
          display: flex;
          justify-content: flex-start;
          align-items: center;
        }
      }
    }
    .buttons {
      margin-bottom: calc(var(--gutter));
      .itemButton,
      .buyButton,
      .wantlistButton,
      .shareButton {
        cursor: pointer;
        width: 100%;
        display: flex;
        justify-content: center;
        margin-bottom: calc(var(--gutter) / 2);
      }
      .buyButton {
        display: flex;
        flex-direction: column;
        &:not(.available) {
          cursor: initial;
        }
        & > .top {
          display: flex;
          align-items: center;
          flex-direction: row;
          justify-content: space-between;
          margin-bottom: var(--gutter);
        }
        & > .bottom {
          font-size: 0.7rem;
        }
      }
    }
  }

  .twoColumns {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: $columGap;
  }

  .threeColumns {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: $columGap;
  }

  &.twoColumnsLeft {
    & > .content {
      & > .top {
        text-align: center;
        margin-bottom: calc(var(--gutter) * 2);
      }
      & > .twoColumns {
        & > .right {
          & > * {
            margin-bottom: var(--gutter);
          }
        }
      }
    }
  }

  &.twoColumnsRight {
    & > .content {
      & > .left {
        & > * {
          margin-bottom: var(--gutter);
        }
      }
    }
  }

  &.threeColumnsGeneric {
    .content {
      .middle {
        & > * {
          margin-bottom: var(--gutter);
        }
      }
    }
    &.threeColumnsCenter {
      .content {
        .left {
          grid-row: 1 / 2;
          grid-column: 2 / 3;
        }
        .middle {
          grid-row: 1 / 2;
          grid-column: 1 / 2;
        }
        .right {
          grid-row: 1 / 2;
          grid-column: 3 / 4;
        }
      }
    }
  }

  &.mobile {
    .content {
      .title {
        & > * {
          text-align: center;
        }
      }
    }
  }
}
