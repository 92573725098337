.tile.releaseItem,
.tile.productItem,
.tile.bookItem {
  .priceAndActions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: calc(var(--gutter) / 2);
    .price {
      display: flex;
      align-items: center;
    }
    .actions {
      button {
        padding: 2px;
        margin-left: 5px;
      }
    }
  }
}

.list {
  display: grid;
  column-gap: var(--gutter);
  grid-template-columns: 0.8fr 3fr 2fr;

  &.mobile {
    grid-template-columns: 1fr 3fr;
    margin-bottom: calc(var(--gutter) * 1);
    .priceAndActions {
      grid-column-start: 1;
      grid-column-end: span col1-end;
      display: flex;
      justify-content: space-between;
      .price {
        margin-right: calc(var(--gutter) * 1);
      }
    }
  }
  .priceAndActions {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    .price {
      margin-right: calc(var(--gutter) * 2);
    }
    .actions {
      & > * {
        margin-left: calc(var(--gutter) / 1);
      }
    }
  }

  &.productItem {
    .title {
      font-weight: bold;
    }
  }
  &.bookItem {
    .title {
      font-weight: bold;
    }
  }
  &.releaseItem {
    .artists {
      font-weight: bold;
    }
  }
}

.full {
  display: grid;
  grid-gap: var(--gutter);
  grid-template-columns: 1fr 2fr;

  &.mobile {
    grid-template-columns: 1fr;
  }

  .actions {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    & > .right > * {
      margin-left: calc(var(--gutter) / 2);
    }
  }
  &.productItem {
    .title {
      font-weight: bold;
    }
  }
  &.bookItem {
    .title {
      font-weight: bold;
    }
  }
  &.releaseItem {
    .artists {
      font-weight: bold;
    }
    .title {
      margin-bottom: var(--gutter);
    }
    .specs,
    .actions,
    .shortDescription {
      margin-bottom: var(--gutter);
    }
    .tracklist {
      margin-bottom: var(--gutter);
      .track {
        display: flex;
        justify-content: space-between;
        margin-bottom: calc(var(--gutter) / 4);
        .left {
          & > * {
            margin-right: var(--gutter);
          }
          display: flex;
          justify-content: flex-start;
          align-items: center;
        }
      }
    }
  }
}
